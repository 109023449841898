import Container from "react-bootstrap/Container"
import Layout from "components/layout"
import LocalizedLink from "components/localized-link"
import { MDXRenderer } from "gatsby-plugin-mdx"
import PropTypes from "prop-types"
import React from "react"
import { graphql } from "gatsby"

export default function BlogPost({ data }) {
  const post = data.mdx
  return (
    <Layout>
      <Container style={{ maxWidth: 900 }}>
        <div>
          <h1 className="text-center" style={{ fontSize: 32, marginTop: 100 }}>
            {post.frontmatter.title}
          </h1>
          <span
            className="text-muted d-block mb-2 text-center mt-4 mb-5"
            style={{ fontSize: 15 }}
          >
            {post.frontmatter.date}{" "}
          </span>

          <MDXRenderer>{post.body}</MDXRenderer>
        </div>
        <LocalizedLink
          to="/blog/"
          role="button"
          className="d-block btn btn-primary  my-5 p-2 px-4 shadow"
          style={{ width: 150 }}
        >
          &larr; Blog&apos;a Dön
        </LocalizedLink>
      </Container>
    </Layout>
  )
}
export const query = graphql`
  query($locale: String!, $slug: String!) {
    mdx(
      frontmatter: { slug: { eq: $slug } }
      fields: { locale: { eq: $locale } }
    ) {
      body
      frontmatter {
        title
        date(formatString: "DD MMMM yyyy, dddd", locale: "tr")
      }
    }
  }
`

BlogPost.propTypes = {
  data: PropTypes.object,
}
